<template>
        <router-view />
        <div class="startup-overlay" v-if="showPopupAdver">
            <div class="popup-center">
                <a href="https://6r6j.xyz/lvcha/?ref=yuan7" target="_blank">
                    <!-- img :src="@/assets/" /-->
                    <img src="@/assets/live.lvcha.437x604.gif" alt="Advertisement" class="ad-image" />
                </a>
                <!--button shape="round" size="large" @click="closeOverlay" class="close-button">×</button-->
                <div class="close-button" @click="closePopup">
                    <svg t="1722616487378" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="85985" width="50" height="50"><path d="M696.64 374.624a32 32 0 0 0-45.28-45.248l45.248 45.248z m-369.28 278.72a32 32 0 1 0 45.28 45.248l-45.248-45.248z m324 45.248a32 32 0 0 0 45.248-45.248l-45.248 45.248z m-278.72-369.216a32 32 0 1 0-45.248 45.248l45.248-45.248z m278.72 0L327.392 653.344l45.248 45.248 323.968-323.968-45.248-45.248z m45.248 323.968L372.64 329.376l-45.248 45.248 323.968 323.968 45.248-45.248zM896 512c0 212.064-171.936 384-384 384v64c247.424 0 448-200.576 448-448h-64zM512 896C299.936 896 128 724.064 128 512H64c0 247.424 200.576 448 448 448v-64zM128 512C128 299.936 299.936 128 512 128V64C264.576 64 64 264.576 64 512h64zM512 128c212.064 0 384 171.936 384 384h64c0-247.424-200.576-448-448-448v64z" fill="#ffffff" p-id="85987"></path></svg>
                </div>
            </div>
        </div>
        <div class="all-screen" v-if="showAllScreenAdver">
            <div style="height: 100%; position: relative;">
                <a href="https://6r6j.xyz/lvcha/?ref=yuan7" target="_blank">
                    <img src="@/assets/ezgif-7-e373c6bd0b.gif" />
                </a>
                <div class="close-button" @click="closeAllScreen">
                    <span v-if="this.startupReciprocal>0">{{ startupReciprocal }}s</span> 关闭
                </div>
            </div>
        </div>
</template>

<script>
import { Modal } from 'ant-design-vue'

export default {
  name: 'App',
  components: {

  },
  data() {
    return {
      appDevice: 0,
      currentVersion: '2.3.0',
      latestVersion: '2.3.0',
      startupReciprocal: 7,
      showPopupAdver: true, // 弹窗口广告是否显示
      showAllScreenAdver: true, // 全屏广告是否显示
      carouselItems: [],
    };
  },
  created() {
  },
  mounted() {
    this.$store.dispatch('fetchCategories');
    this.$store.dispatch('fetchAdvers');
    this.startCountdown();
  },
  methods: {
    versionUpdateCheck() {
      const userdata = localStorage.getItem('userdata');
      const isMember = (userdata)?1:0;
      const url = 'https://yqapi.fkdlapp.live/index/latest_version/app_device/'+this.appDevice+'/is_member/'+isMember;
      fetch( url )
        .then(response => response.json())
        .then(data => {
          if (data['success']) {
            this.latestVersion = data['data']['latest_version'];
            console.log('当前APP版本：'+this.currentVersion);
            console.log('最新APP版本：'+this.latestVersion);
            if ( this.latestVersion > this.currentVersion ) {
              Modal.confirm({
                title: '发现新版本',
                content: '请及时更新APP客户端到最新版！网页端用户请在浏览器刷新即可',
                okText: '立即更新',
                onOk: () => {
                  //this.$router.push({ path: '/appdownload' });
                  window.open('https://yuan7.vip/');
                },
                cancelText: '以后再说',
                  centered: true,
              });
            }
          }
        })
        .catch(error => {
          console.log(error.message);
        });
    },
    startCountdown() {
      this.timer = setInterval(() => {
        this.startupReciprocal--;
        if (this.startupReciprocal === 0) {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    closeAllScreen() {
      if( this.startupReciprocal === 0 ) {
        this.showAllScreenAdver = false; // 关闭全屏广告
      }
    },
    closePopup() {
      this.showPopupAdver = false; // 关闭弹窗广告
      this.versionUpdateCheck(); // 版本更新检查
    },
  },
}
</script>

<style>
/*
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
*/


.ant-card .ant-card-body {
    padding: 8px;
}
.ant-card-meta>.ant-card-meta-detail>.ant-card-meta-title {
    /*margin-bottom: 0!important;
    overflow: hidden;*/
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 设置行数 */
    white-space: unset;
    font-size: 15px;
    font-weight: normal;
}

.all-screen {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
}
.all-screen img {
  height: 100%;
  width: 100%;
}
.all-screen .close-button {
  height: 37px;
  position: absolute;
  top: 17px;
  right: 17px;
  padding: 5px 17px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50px;
  color: #fff;
  font-size: 17px;
}

.startup-overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75); /* 半透明黑色背景 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.startup-overlay>.popup-center {
  position: relative;
  width: 75%;
  height: 414px;
}

.startup-overlay .ad-image {
    height: 360px;
    width: 100%;
    border-radius: 12px;
}

.startup-overlay .close-button {
  position: absolute;
  z-index: 101;
  bottom: 0;
  left: 50%;
  cursor: pointer;
  /*margin-left: -25px;*/
  transform: translate(-50%, 0);
  font-size: 36px;
  width: 50px;
  height: 50px;
  color: #fff;
  /*background: transparent;*/
}
</style>
